<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5 text-light font-weight-bold"
          style="font-size: 3.5vw"
        >
          Let’s start playing
          <br />the game to better
          <br />your future
        </div>
      </b-col>
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-link style="position: absolute; top:0; right: 0" to="dashboard">
          <img src="@/assets/images/logo/logo.png" alt class="logo" />
        </b-link>
        <b-col sm="8" md="6" lg="8" class="px-xl-2 mx-auto" v-if="successMsg">
          <h3 class="text-light text-center">{{successMsg}}</h3>
        </b-col>
        <b-col sm="8" md="6" lg="8" class="px-xl-2 mx-auto" v-else>
          <b-card-title class="mb-1 text-muted">Forgot Password?</b-card-title>
          <b-card-text
            class="mb-2"
          >Enter your email and we'll send you instructions to reset your password</b-card-text>

          <validation-observer ref="simpleRules">
            <b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
              <b-form-group label="Email" label-for="forgot-password-email" class="light-label">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  class="inner-addon left-addon"
                  rules="required|email"
                >
                  <feather-icon icon="MailIcon" size="18" />
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                    class="custom-form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || load"
                class="rounded-pill shadow-none mt-3"
                style="background-color: #E84185 !important; border-color: #E84185 !important"
              >
                <center v-if="load">
                  <b-spinner variant="light" label="Spinning"></b-spinner>
                </center>
                <span v-else>
                  Send reset link
                </span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}" style="color: #E84185">
              <feather-icon icon="ChevronLeftIcon" />Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
  BButton
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      // validation
      required,
      email,
      successMsg: null
    };
  },
  computed: {
    ...mapGetters({
      load: "users/load",
    }),
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const payload = { query: { email: this.userEmail } };
          this.$store
            .dispatch("users/forgotPassword", payload)
            .then(res => {
              localStorage.setItem("userEmail", this.userEmail);
              this.successMsg =
                "Reset password link sent successfully, Please check your mail";
            })
            .catch(error => {
              if (error.response) {
                this.$swal({
                  icon: "error",
                  title: `<h4>${error.response.data.message}</h4>`,
                  showConfirmButton: true,
                  allowOutsideClick: true,
                  confirmButtonColor: "#E84185"
                });
              }
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  font-family: "Inter", sans-serif !important;
}
.logo {
  width: 200px;
}
@media (max-width: 992px) {
  .logo {
    width: 100px;
  }
  .auth-inner {
    display: flex !important;
    justify-content: center !important;
  }
}

.auth-wrapper {
  background: url("../../../assets/images/banner/dark_bakground.png") no-repeat;
  background-size: cover;
}
.auth-bg {
  background-color: #5a5a5a32 !important;
  color: #fff !important;
}
.custom-form-control {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #888 !important;
  height: 50px;
}
.inner-addon {
  position: relative;
}
.inner-addon .feather-mail,
.feather-lock {
  position: absolute;
  top: 16px;
  left: 10px;
  // pointer-events: none;
}

.left-addon input {
  padding-left: 40px;
  color: #fff !important;
}
.password-addon {
  padding-left: 40px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  color: #fff !important;
}
</style>